import axios from 'axios';
import Config from './../../config/index';
const getDashboardData = async (
  email: string,
  code: string,
  amcName: string
) => {
  const url = `${Config.baseUrl}/dashboard/amc-dashboard?email=${email}&amc_name=${amcName}&amc_code=${code}`;
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
};

const getDashboardTransactions = async (
  email: string,
  start_date: string,
  end_date: string,
  amc_code: string
) => {
  const url = `${Config.baseUrl}/dashboard/dashboard-txn?email=${email}&amc_code=${amc_code}&start_date=${start_date}&end_date=${end_date}`;
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
};

const ConvertToExponential = (num, digits) => {
  if (num == 0) {
    return 0;
  } else {
    const symbols = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const numToCheck = Math.abs(num);
    for (let i = symbols.length - 1; i >= 0; i--) {
      if (numToCheck >= symbols[i].value) {
        const newNumber = (num / symbols[i].value).toFixed(digits);
        return `${newNumber}${symbols[i].symbol}`;
      }
    }
    return num.toExponential(digits);
  }
};

export { getDashboardData, getDashboardTransactions, ConvertToExponential };

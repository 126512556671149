const Config = {

    // Naya Tell
    // baseUrl: "http://124.109.39.158:3000/api", //deployed
    // socketBaseUrl: "http://124.109.39.158:3000/", //deployed
    // imageBaseUrl: "http://124.109.39.158:3000/", //forimage 

    // PTCL IP
    baseUrl: "http://182.191.93.242:3000/api", //deployed
    socketBaseUrl: "http://182.191.93.242:3000/", //deployed
    imageBaseUrl: "http://182.191.93.242:3000/", //forimage

    // baseUrl: "..//182.191.93.242:3000/api", //deployed
    // socketBaseUrl: "..//182.191.93.242:3000/", //deployed
    // imageBaseUrl: "..//182.191.93.242:3000/", //forimage

    // VPS
    // baseUrl: "http://167.86.69.108:3000/api", //deployed
    // socketBaseUrl: "http://167.86.69.108:3000/", //deployed
    // imageBaseUrl: "http://167.86.69.108:3000/", //forimage
}
export default Config
import React from 'react';
import { useState } from 'react';
// import Header from './../components/Header';
import { Container, Row, Col, FormGroup,Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import {
  fieldData,
  transactionObj,
  fieldValue,
  chechFieldDisplay,
  getCounterAccount,
  checkTaxBoolean,
} from './field';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

const ViewTxnFields = (props: any) => {
  console.log('props', props);
  const history = useHistory();
  const [headLabel, setHeadLabel] = React.useState('');
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

  const [systemTaxFlag, setSystemTaxFlag] = useState(false);
  const [systemTax, setSystemTax] = useState<any>();
  const [termDeposit, setTermDeposit] = useState<any>([]);
  const [checkChanges, setCheckChanges] = useState('');
  
  const [realizedCheck, setRealizedCheck] = useState(false);

  const requiredFields = transactionObj(headLabel);
  let counterAccounter = getCounterAccount(headLabel);
  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  React.useEffect(() => {
    fields['realized'] === 'true' ? setRealizedCheck(true) : setRealizedCheck(false);

    if (props.data) {
      if (props.flag) {
        const obj = JSON.parse(sessionStorage.getItem('csab-txn') || '');
        setHeadLabel(obj.txnr_type);
        counterAccounter = getCounterAccount(obj.txnr_type);
        setFields(obj);
        if(props.data.txnr_type==='termdeposit'){
          // debugger;
          setTermDeposit(JSON.parse(fields.securities));
        }
      } else {
        setHeadLabel(props.data.txnr_type);
        counterAccounter = getCounterAccount(props.data.txnr_type);
        if(props.data.txnr_type==='termdeposit'){
          setTermDeposit(JSON.parse(fields.securities));
        }
      }
    }
  }, [props]);

 
  const [fields, setFields] = useState(props.data);
  // React.useEffect(()=>{
  //   if(props.data.txnr_type==='termdeposit'){
  //     setTermDeposit(JSON.parse(fields.securities));
  //   }
  //  },[])

  const [flagForSecutities, setFlagForSecutities] = useState(false);
  const renderSecuritiesModalPopup = () => {
    switch (flagForSecutities) {
      case true:
        return (
            <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => { setFlagForSecutities(false) }}
        > 
        <div className="modal-header ">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setFlagForSecutities(false); 
            }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">View Security  </h6>
          </div><div className="modal-header ">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setFlagForSecutities(false); 
            }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">View Security  </h6>
          </div>
            <div className="modal-body">
                <div className="">
                    {securites.length > 0 &&
                        <div className="form-group">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Market</th>
                                        <th>Symbol</th>
                                        <th>Quantity</th>
                                        <th>Avg Rate</th>
                                        <th>Gross Amount</th>
                                        <th>Charges</th>
                                        <th>Net Amount</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {renderPsxData()}
                                </tbody>
                            </table>
                        </div>}
                </div>
            </div>
        </Modal>
        );
      default:
        return '';
    }
  };

  const [securites, setSecurites] = useState<any>([]);
  const renderPsxData = () => {
    if (securites === '[]') {
      return;
    }
    if (securites === '') {
      return;
    }
    return securites.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.type}</td>
          <td>{items.market}</td>
          <td>{items.symbol}</td>
          <td>{parseFloat(items.quantity).toFixed(4)}</td>
          <td>{parseFloat(items.avg_rate).toFixed(4)}</td>
          <td>{parseFloat(items.gross_amount).toFixed(2)}</td>
          <td>{parseFloat(items.charges).toFixed(2)}</td>
          <td>{parseFloat(items.net_amount).toFixed(2)}</td>
        </tr>
      );
    });
  };

  const [flagForMoneyMarketSecutities, setFlagForMoneyMarketSecutities] = useState(false);
  const renderMoneyMarketSecuritiesModalPopup = () => {
    switch (flagForMoneyMarketSecutities) {
      case true:
        return (
            <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => { setFlagForMoneyMarketSecutities(false) }}
        > 
        <div className="modal-header ">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setFlagForMoneyMarketSecutities(false); 
            }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">View Money Market Security  </h6>
          </div><div className="modal-header ">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setFlagForMoneyMarketSecutities(false); 
            }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            {/* <h6 className="title title-up">View Security  </h6> */}
          </div>
            <div className="modal-body">
                <div className="">
                    {securites.length > 0 &&
                        <div className="form-group">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Security Type</th>
                                        <th>Type</th>
                                        <th>Symbol</th>
                                        <th>Name</th>
                                        <th>Issue Date</th>
                                        <th>Maturity Date</th>
                                        <th>Face Value</th>
                                        <th>Price</th>
                                        <th>Units</th>
                                        <th>Yield</th>
                                        <th>Coupon Rate</th>
                                        <th>Next Coupon Date</th>
                                        <th>Last Coupon date</th>
                                        <th>Primary Dealer</th>
                                        <th>Fund IPS Account</th>
                                        <th>Counter Party IPS Account</th>
                                        <th>Settlement Amount</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {renderMoneyMarketSecurityData()}
                                </tbody>
                            </table>
                        </div>}
                </div>
            </div>
        </Modal>
        );
      default:
        return '';
    }
  };


  const renderMoneyMarketSecurityData = () => {
    if (securites === '[]') {
      return;
    }
    if (securites === '') {
      return;
    }
    return securites.map((items: any, index: number) => {
      return (
        <tr key={index}>
         
          <td>{items.security_type}</td>
          <td>{items.type}</td>
          <td>{items.symbol}</td>
          <td>{items.name}</td>
          <td>{items.issue_date}</td>
          <td>{items.maturity_date}</td>
          <td>{items.money_market_face_value}</td>
          <td>{items.price}</td>
          <td>{items.units}</td>
          <td>{items.yield}</td>
          <td>{items.coupon_rate}</td>
          <td>{items.next_coupon_date}</td>
          <td>{items.last_coupon_date}</td>  
          <td>{items.primary_dealer}</td>
          <td>{items.fund_ips_account}</td>
          <td>{items.counter_party_ips_account}</td>
          <td>{items.settlement_amount}</td>
        </tr>
      );
    });
  };

  const renderSystemTaxData = () => {
    return (
      <tr>
        <td>{parseFloat(systemTax.amount).toFixed(2)}</td>
        <td>{systemTax.sst_rate}%</td>
        <td>{parseFloat(systemTax.sst_amount).toFixed(2)}</td>
        <td>{parseFloat(systemTax.total_amount).toFixed(2)}</td>
        <td>{systemTax.wht_it_rate}%</td>
        <td>{parseFloat(systemTax.wht_it_amount).toFixed(2)}</td>
        <td>{systemTax.wht_sst_rate}%</td>
        <td>{parseFloat(systemTax.wht_sst_amount).toFixed(2)}</td>
        <td>{parseFloat(systemTax.net_amount).toFixed(2)}</td>
      </tr>
    );
  };
  const [viewSystemTaxModal, setSystemTaxModal] = useState(false);
  const renderSystemTaxPopup = () => {
    switch (viewSystemTaxModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setSystemTaxModal(false);
            }}
          > 

            <div className="modal-header ">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setSystemTaxModal(false);
              }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Tax Details </h6>
          </div> 
            <div className="modal-body"> 
            {checkTaxBoolean(headLabel) && fields.system_tax !== '' && (
              <>
                  <h4 className="text-primary text-center" >System Tax</h4>
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>Amount</th>
                        <th>Rate (SST)</th>
                        <th>Amount (SST)</th>
                        <th>Total Amount</th>
                        <th>Rate (WHT IT)</th>
                        <th>WHT IT AMOUNT</th>
                        <th>Rate WHT SST</th>
                        <th>SST WHT IT AMOUNT</th>
                        <th>Net Amount</th>
                      </tr>
                    </thead>
                    <tbody>{renderSystemTaxData()}</tbody>
                  </table> 
                </>
                   )}

                  {totalTax.length > 0 ? (
                    <>
                  <h4 className="text-primary text-center"> Tax Charges</h4>
                  <div className="form-group">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Tax Name</th>
                          <th>Tax Amount</th>
                          <th>Calculated Amount</th>
                        </tr>
                      </thead>
                      <tbody>{renderTaxData()}</tbody>
                    </table>
                  </div>
                  </>
                ) : 'Taxes/charges were not enterred !' }
                </div> 
          </Modal>
        );
      default:
        return '';
    }
  };

  const [totalTax, setTotalTax] = useState<any>([]);
  //render Tax table data
  const renderTaxData = () => {
    if (totalTax === '[]') {
      return;
    }
    if (totalTax === '') {
      return;
    }
    return totalTax.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.name}</td>
          <td>{parseFloat(items.amount).toFixed(2)}</td>
          <td>{parseFloat(items.calculatedAmount).toFixed(2)}</td>
        </tr>
      );
    });
  };

  const [viewTaxModal, setTaxModal] = useState(false);
  const renderTaxPopup = () => {
    switch (viewTaxModal) {
      case true:
        return (
            <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => { setTaxModal(false) }}
        > 
        <div className="modal-header ">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setTaxModal(false); 
            }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">View Txn Charges  </h6>
          </div>
            
            <div className="modal-body">
                <div className="">
                {totalTax.length > 0 &&
                        <div className=" ">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Tax Name</th>
                                        <th>Tax Amount</th>
                                        <th>Calculated Amount</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {renderTaxData()}
                                </tbody>
                            </table>
                        </div>}
                </div>
            </div>
        </Modal>
        );
      default:
        return '';
    }
  };

  return (
    <div className="">
      {/* <div className="">
        <h4 className="card-title">{CapTxnType(headLabel)} Transaction</h4>
      </div> */}
      <Row>
        <Col>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Instruction</h4>
            </div>
            <div className="card-body">
              <div>
                {/* fund dropdown , itx value depend on amc value  */}
                <div className="form-group">
                  Fund Name
                  <div className="form-control  w-100">
                    {fields['fund_code']}
                  </div>
                </div>
                <div className="form-group">
                  Instruction Date
                  <div className="form-control w-100">
                    {fields['instruction_date']}
                  </div>
                </div>
                <div className="form-group">
                  Execution Date
                  <div className="form-control w-100">
                    {fields['execution_date']}
                  </div>
                </div>
                <div className="form-group">
                  Mode of Payment
                  <div className="form-control w-100">
                    {fields['mode_of_payment']}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                {headLabel === 'fundtransfer' ? 'From Account' : 'Fund Account'}
              </h4>
            </div>
            <div className="card-body">
              <div>
                <div className="form-group">
                  Fund Account
                  <div className="form-control w-100">
                    {fields['fund_account']}
                  </div>
                </div>
                <div className="form-group">
                  Account Title
                  <div className="form-control w-100">
                    {fields['account_title']}
                  </div>
                </div>
                <div className="form-group">
                  Account Number
                  <div className="form-control w-100">
                    {fields['account_number']}
                  </div>
                </div>
                <div className="form-group w-100 row">
                  <div className="col-6 flex">
                    <div>Bank</div>
                    <div className="form-control w-100  ">{fields['bank']}</div>
                  </div>
                  <div className="col-6 flex">
                    <div>Branch</div>
                    <div className="form-control w-100  ">{fields['branch']}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                {headLabel === 'fundtransfer'
                  ? 'To Account'
                  : 'Counter Account'}
              </h4>
            </div>
            <div className="card-body">
              <div>
                <div className="form-group">
                  <div className="text-capitalize">{counterAccounter}</div>
                  <div className="form-control  w-100">
                    {fields['counter_account_type']}
                  </div>
                </div>
                <div className="form-group">
                  Account Title
                  <div className="form-control  w-100">
                    {fields['counter_account_title']}
                  </div>
                </div>
                <div className="form-group">
                  Account Number
                  <div className="form-control w-100">
                    {fields['counter_account_number']}
                  </div>
                </div>
                <div className="form-group w-100 row">
                  <div className="col-6 flex">
                    <div>Bank</div>
                    <div className="form-control w-100  ">
                      {fields['counter_bank']}
                    </div>
                  </div>
                  <div className="col-6 flex">
                    <div>Branch</div>
                    <div className="form-control w-100  ">
                      {fields['counter_branch']}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row  >

      <Col>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Instrument</h4>
            </div>
            <div className="card-body">
              <div>
                <div className="form-group">
                  Mode of Payment
                  <div className="form-control   w-100">
                    {fields['mode_of_payment']}
                  </div>
                </div>
                <div className="form-group">
                  Instrument Type
                  <div className="form-control   w-100">
                    {fields['instrument_type']}
                  </div>
                </div>
                <div className="form-group">
                      Instrument No./Reference No
                      <div className="form-control    w-100">
                        {fields['instrument_no']}
                      </div>
                    </div>
                {fields.payment_type !== 'Receipt' &&
                fields.txn_status === 'COMPLETED' ? (
                  <>
                    {/* <div className="form-group">
                      Instrument No./Reference No
                      <div className="form-control    w-100">
                        {fields['instrument_no']}
                      </div>
                    </div> */}
                    <div className="form-group">
                      Instrument Date / Deposit Date
                      <div className="form-control   w-100">
                        {moment(fields['instrument_date']).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              Transaction Processing
            </h4>
          </div>
          <div className="card-body">

            <Row>
              <Col md="6">
              <div className="form-group">
                <div>Transaction Type</div>
                  <div className="form-control   w-100">
                  { CapTxnType( fields['txnr_type'] )}
                  </div>
              </div> 

              </Col>
              <Col md="6">
              <div className="form-group">
                <div>Transaction ID</div>
                  <div className="form-control   w-100">
                  {fields['txn_id']}
                  </div>
              </div>
              </Col>
            </Row> 
          

              <div className="form-group">
                <div> Created By</div>
                  <div className="form-control   w-100">
                  {fields['created_by']}
                </div>
              </div> 
              <div className="form-group">
                <div> Status</div>
                  <div className="form-control   w-100">
                  {fields['txn_status']}
                </div>
              </div> 

              <Row>
              <Col md="6"> 

                  <FormGroup check className="mt-3">
                        
                    <Input type="checkbox"
                      checked={realizedCheck} readOnly />
                    <span className="form-check-sign" />
                    <span className="ml-4 mt-minus6">  Realized In Bank </span>
                         
                  </FormGroup> 
              </Col>
              <Col md="6">
              <div className="form-group">
                  <div>Realized Date</div>
                  <div className="form-control   w-100">
                  {fields['realized_date']}
                  </div>
                </div>
              </Col>
            </Row>
              
          </div>
          </div>
        </Col>


        <Col>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Transaction Details</h4>
            </div>
            <div className="card-body">
              <div>
                {chechFieldDisplay(headLabel, 'folio_no') && (
                  <div className="form-group">
                    Folio Number
                    <div className="form-control  w-100">
                      {fields['folio_no']}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'units') && (
                  <div className="form-group">
                    Units{' '}
                    {headLabel === 'redemptionofunits'
                      ? 'Redeemed'
                      : headLabel === 'saleofunit' ||
                        headLabel === 'conversionin'
                      ? 'Sold'
                      : headLabel === 'salereturn'
                      ? 'Returned'
                      : ''}
                    <div className="form-control  w-100">
                      {parseFloat(fields['units']).toFixed(4)}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'nav') && (
                  <div className="form-group">
                    Nav
                    <div className="form-control w-100">
                      {parseFloat(fields['nav']).toFixed(4)}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'type') && (
                  <div className="form-group">
                    Type
                    <div className="form-control w-100">{fields['type']}</div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'sale_date') && (
                  <div className="form-group">
                    Sale Date
                    <div className="form-control w-100">
                      {fields['sale_date']}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'sale_txn_no') && (
                  <div className="form-group">
                    Sale Txn No
                    <div className="form-control w-100">
                      {fields['sale_txn_no']}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'return_date') && (
                  <div className="form-group">
                    Return Date
                    <div className="form-control w-100">
                      {fields['return_date']}
                    </div>
                  </div>
                )}

               {headLabel === 'conversionout' &&
                fields.txn_status === 'COMPLETED' ? (
                  <>
                    {/* {chechFieldDisplay(headLabel, 'total_holding') && ( */}
                  <div className="form-group">
                    Total Holding
                    <div className="form-control w-100">
                      0
                    </div>
                  </div>
                 {/* )} */}
                  </>
                ) : (
                  <>
                  {chechFieldDisplay(headLabel, 'current_holding') && (
                    <div className="form-group">
                      Current Holding
                      <div className="form-control w-100">
                        {/* {parseFloat(fields['current_holding']).toFixed(4)} */}
                        {fields['current_holding']
                        ? parseFloat(fields['current_holding']).toFixed(4)
                        : '0.0000'}
                      </div>
                    </div>
                  )}
                  </>
                )}         
                {/* {chechFieldDisplay(headLabel, 'current_holding') && (
                  <div className="form-group">
                    Current Holding
                    <div className="form-control w-100">
                      {parseFloat(fields['current_holding']).toFixed(4)}
                    </div>
                  </div>
                )} */}
                
                {chechFieldDisplay(headLabel, 'symbol') && (
                  <div className="form-group">
                    Symbol
                    <div className="form-control w-100">{fields['symbol']}</div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'dividend_percentage') && (
                  <div className="form-group">
                    Dividend Percentage
                    <div className="form-control w-100">
                      {fields['dividend_percentage']}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'credit_date') && (
                  <div className="form-group">
                    Credit Date
                    <div className="form-control w-100">
                      {fields['credit_date']}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'maturity_type') && (
                  <div className="form-group">
                    Maturity Type
                    <div className="form-control w-100">
                      {fields['maturity_type']}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'security_type') && (
                  <div className="form-group">
                    Security Type
                    <div className="form-control  w-100">
                      {fields['security_type']}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'primary_dealer') && (
                  <div className="form-group">
                    Primary Dealer
                    <div className="form-control    w-100">
                      {fields['primary_dealer']}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'issue_date') && (
                  <div className="form-group">
                    Issue Date
                    <div className="form-control w-100">
                      {fields['issue_date']}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'conversion_date') && (
                  <div className="form-group">
                    Conversion Date
                    <div className="form-control w-100">
                      {fields['conversion_date']}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'associated_transaction') && (
                  <div className="form-group">
                    Associated Txn No
                    <div className="form-control w-100">
                      {fields['associated_transaction']}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'maturity_date') && (
                  <div className="form-group">
                    Maturity Date
                    <div className="form-control w-100">
                      {fields['maturity_date']}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'coupon_rate') && (
                  <div className="form-group">
                    Coupon Rate / Yield
                    <div className="form-control w-100">
                      {parseFloat(fields['coupon_rate']).toFixed(2)}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'price') && (
                  <div className="form-group">
                    Price
                    <div className="form-control w-100">
                      {parseFloat(fields['price']).toFixed(2)}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'face_value') && (
                  <div className="form-group">
                    Face Value
                    <div className="form-control  w-100">
                      {parseFloat(fields['face_value']).toFixed(2)}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'money_market_face_value') && (
                  <div className="form-group">
                    Money Market Face Value
                    <div className="form-control  w-100">
                      {parseFloat(fields['money_market_face_value']).toFixed(2)}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'detail') && (
                  <div className="form-group">
                    Detail
                    <div className="form-control w-100">{fields['detail']}</div>
                  </div>
                )}

                {(headLabel==='termdeposit' && termDeposit.length>0) ? (
                    <>
                    <div className="form-group">
                     Type
                    <div className="form-control w-100">{termDeposit[0]['name']}</div>
                  </div>
                     <div className="form-group">
                     Principal Amount
                    <div className="form-control w-100">{termDeposit[0]['principal_amount']}</div>
                  </div>
                  <div className="form-group">
                     Interest Rate
                    <div className="form-control w-100">{termDeposit[0]['interest_rate']}</div>
                  </div>

                  <div className="form-group">
                    From Date
                   <div className="form-control w-100">{termDeposit[0]['from_date']}</div>
                  </div>

                  <div className="form-group">
                    To Date
                    <div className="form-control w-100">{termDeposit[0]['to_date']}</div>
                  </div>

                  </>
                  ):(
                  //   <>
                  //  <div className="form-group">
                  //    Type
                  //   <div className="form-control w-100">{termDeposit['name']}</div>
                  // </div>
                  //    <div className="form-group">
                  //    Principal Amount
                  //   <div className="form-control w-100">{termDeposit['principal_amount']}</div>
                  // </div>
                  // <div className="form-group">
                  //    Interest Rate
                  //   <div className="form-control w-100">{termDeposit['interest_rate']}</div>
                  // </div>

                  // <div className="form-group">
                  //   From Date
                  //  <div className="form-control w-100">{termDeposit['from_date']}</div>
                  // </div>

                  // <div className="form-group">
                  //   To Date
                  //   <div className="form-control w-100">{termDeposit['to_date']}</div>
                  // </div>
                  //   </>
                  ''
                  )}

                {chechFieldDisplay(headLabel, 'redemption_date') && (
                  <div className="form-group">
                    Redemption Date
                    <div className="form-control w-100">
                      {fields['redemption_date']}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'remain_holding') && (
                  <div className="form-group">
                    Remaining Holding
                    <div className="form-control w-100">
                      {parseFloat(fields['remain_holding']).toFixed(4)}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'settlement_by') && (
                  <div className="form-group">
                    Settlement By*
                    <div className="form-control w-100">
                      {fields['settlement_by']}
                    </div>
                  </div>
                )}
                {chechFieldDisplay(headLabel, 'settlement_date') && (
                  <div className="form-group">
                    Settlement Date
                    <div className="form-control w-100">
                      {fields['settlement_date']}
                    </div>
                  </div>
                )}
                {/* {headLabel === 'moneymarketpurchase' && (
                  <div className="form-group">
                    <div>Fund IPS Account</div>
                    <div className="form-control w-100">
                      {fields['fund_ips_account']}
                    </div>
                  </div>
                )}
                {headLabel === 'moneymarketpurchase' && (
                  <div className="form-group">
                    <div>Counter Party IPS Account</div>
                    <div className="form-control w-100">
                      {fields['counter_party_ips_account']}
                    </div>
                  </div>
                )} */}
                {chechFieldDisplay(headLabel, 'tax_type') && (
                  <div className="form-group">
                    Tax Type
                    <div className="form-control w-100">
                      {fields['tax_type']}
                    </div>
                  </div>
                )}
                {headLabel === 'equityinvestment' && (
                  <div className="form-group mb-4">
                    {fields.securities !== '[]' && fields.securities !== '' && (
                      <div
                        className="btn btn-primary"
                         
                        onClick={() => {
                          setFlagForSecutities(true);
                          setSecurites(JSON.parse(fields.securities));
                        }}
                      >
                        View Securities
                      </div>
                    )}
                  </div>
                )}
                {headLabel === 'debtmarketinvestment' && (
                  <div className="form-group mb-4">
                    {fields.securities !== '[]' && fields.securities !== '' && (
                      <div
                        className="btn btn-primary"
                         
                        onClick={() => {
                          setFlagForMoneyMarketSecutities(true);
                          setSecurites(JSON.parse(fields.securities));
                        }}
                      >
                        View Money Market Securities
                      </div>
                    )}
                  </div>
                )}

              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Amount</h4>
            </div>
            <div className="card-body">
              <div>
                <div className="form-group">
                  <div>Gross Amount</div>
                  <div className="form-control w-100">
                    {parseFloat(fields['gross_amount']).toFixed(2)}
                  </div>
                </div>

                {chechFieldDisplay(headLabel, 'tax_sst') && (
                  <div className="form-group">
                    Sale Tax (SST/PST)
                    <div className="form-control w-100">
                      {fields['tax_sst']}
                    </div>
                  </div>
                )}

             {chechFieldDisplay(headLabel, 'tax_wht_it') && (
                  <div className="form-group">
                    WHT IT
                    <div className="form-control w-100">
                      {fields['tax_wht_it']}
                    </div>
                  </div>
                )}
                
                {chechFieldDisplay(headLabel, 'tax_wht_it') && (
                  <div className="form-group">
                    WHT Sale Tax
                    <div className="form-control w-100">
                      {fields['tax_wht_sst']}
                    </div>
                  </div>
                )}

                {chechFieldDisplay(headLabel, 'tax_cgt') && (
                  <div className="form-group">
                    CGT
                    <div className="form-control w-100">
                      {fields['tax_CGT']}
                    </div>
                  </div>
                )}

               {chechFieldDisplay(headLabel, 'sale_load') && (
                  <div className="form-group">
                    Sale Load
                    <div className="form-control w-100">
                      {fields['sale_load']}
                    </div>
                  </div>
                )}

              {chechFieldDisplay(headLabel, 'redemption_load') && (
                  <div className="form-group">
                    Redemption Load
                    <div className="form-control w-100">
                      {fields['redemption_load']}
                    </div>
                  </div>
                )}

                 {/* <div className="form-group">
                  <div className="d-flex space-between">
                  <div>Charges</div>
                      <div className=" ">	
                      {fields.txn_charges !== '[]' && fields.txn_charges !== '' && ( 
                          <a className=" "  	
                          onClick={() => {	
                              // setTaxModal(true);	
                              setTotalTax(JSON.parse(fields.txn_charges))	
                               // system
                               setSystemTaxModal(true);
                               let temp = JSON.parse(fields.system_tax);
                               setSystemTax(temp);
                               setCheckChanges(temp.amount);
                          }}	
                          >	
                          View Tax	
                          </a>

                       )} 
                      </div>
                   </div>  
                  <div className="form-control w-100">
                    {parseFloat(fields['total_charges']).toFixed(2)}
                  </div>
                </div>  */}
                {chechFieldDisplay(headLabel, 'total_charges') && (
                <div className="form-group">
                  <div>Total Amount</div>
                  <div className="form-control  w-100">
                    {parseFloat(fields['total_charges']).toFixed(2)}
                  </div>
                </div>
                )}
                <div className="form-group">
                  <div>Net Amount</div>
                  <div className="form-control  w-100">
                    {parseFloat(fields['net_amount']).toFixed(2)}
                  </div>
                </div>
                
                <div className="form-group">
                  <div> Type</div>
                  <div className="form-control   w-100">
                  {fields['payment_type']}
                  </div>
                </div>

                {/* <div className="form-group">
                  {fields.txn_charges !== '[]' && fields.txn_charges !== '' && (
                    <div
                      className="btn-1 ml-auto flex justify-content-center"
                      style={{
                        background: '#0e4646',
                        color: 'white',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setTaxModal(true);
                        setTotalTax(JSON.parse(fields.txn_charges));
                      }}
                    >
                      View Tax
                    </div>
                  )}
                </div> */}

              <div className="input-size-pos mt-2">
                  {/* {checkTaxBoolean(headLabel) && fields.system_tax !== '' && (
                    <button
                      className="btn btn-primary flex ml-auto justify-content-center" 
                      onClick={() => {
                        setSystemTaxModal(true);
                        let temp = JSON.parse(fields.system_tax);
                        setSystemTax(temp);
                        setCheckChanges(temp.amount);
                      }}
                    >
                      System Tax
                    </button>
                  )} */}
                </div>

              </div>
            </div>
          </div>
        </Col>
      </Row>
      {renderSecuritiesModalPopup()}
      {renderMoneyMarketSecuritiesModalPopup()}
      {renderTaxPopup()}
      {renderSystemTaxPopup()}
    </div>
  );
};

export default ViewTxnFields;


import React, { useState } from 'react';
// import Header from './Header';
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Button,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import ViewTxnFields from '../containers/view-beta-txn';
import { PieChart } from 'react-minimal-pie-chart';
import {
  getDashboardData,
  ConvertToExponential,
  getDashboardTransactions,
} from './../stores/services/dashboard.service';
import { toast, ToastContainer } from 'react-toastify';
import {
  AiOutlineSearch,
  AiOutlineArrowRight,
  AiOutlineCloseCircle,
} from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

const Dash = () => {
  let date = new Date();
  const [data, setData] = useState<any>([]);
  const email = sessionStorage.getItem('email') || '';
  const [loading, setLoading] = useState(false); //Loading hook to deal Api call response
  const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD')); // Starting Date Hook for Filter	
 	
  // const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD'); // Ending Date Hook for Filter	
  const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD')); 
  const [transaction, setTransaction] = useState<any>([]);
  const [loadingTxn, setLoadingTxn] = useState(false); //Loading hook to deal Api call response
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  const [viewFlag, setViewFlag] = useState(false);
  const [txnHeading, setTxnHeading] = useState('');
  const loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser') || '');
  const amc_name = sessionStorage.getItem('amc_name') || '';
  const [saleOfUnitData, setSaleOfUnitData] = useState({ count: 0, amount: '0' });
  const [inflowData, setInflowData] = useState({ count: 0, amount: '0'});

  //Function  to Get Total Counts Values From All Sections Of DashBoard
  React.useEffect(() => {
    // let isMounted = true;
    const getDashData = async () => {
      try {
        const response = await getDashboardData(
          email,
          loggedInUser.amc_code,
          amc_name
        );
        setInflowData({
          count: response.data.recent_txns.managementfee_count,
          amount: parseFloat(response.data.recent_txns.managementfee_amount).toFixed(2) ,
        });
        setSaleOfUnitData({
          count: response.data.recent_txns.saleofunit_count,
          amount: parseFloat(response.data.recent_txns.saleofunit_amount).toFixed(2) ,
        });
        setData(response.data);
      } catch (error) {
        console.log('error ', error);
      }
    };
    getDashData();
    getAllTransaction();
    // return () => { isMounted = false };
  }, []);

 //Function to Get All Type Of filtered Transactions For Dashboard
 const getAllTransaction = async () => {
  setLoading(true);
  try {
    const response = await getDashboardTransactions(
      email,
      fromDate,
      toDate,
      loggedInUser.amc_code
    );
    const tempObj = response.data;
    tempObj.registered_txn.length = 10;
    tempObj.compliance_txn.length = 10;
    tempObj.operation_txn.length = 10;
    tempObj.completed_txn.length = 10;
    setTransaction(tempObj);
  } catch (error) {}
  setLoading(false);
};
const CapTxnType = (type: any) => {
  let i, txType;
  for (i = 0; i < txnTypes.length; i++) {
    if (txnTypes[i].api_name === type) {
      txType = txnTypes[i].dis_name;
      break;
    }
  }
  return txType;
};

const [dataTxn, setDataTxn] = useState<any>([]);
//title case Convert Function For View fields
const titleCase = (value: string) => {
  let sentence = value.toLowerCase().split('_');
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(' ');
};

  //render data in view model
  const renderData = () => {
    return dataTxn.map((value: any, index: number) => {
      return (
        <>
          {value[0] !== 'txn_history' && value[0] !== 'file' ? (
            <Col md="3" key={index}>
              <div className="">
                <label>{titleCase(value[0])}</label>
                <div className="form-group hide-overflow">
                  <p
                    className="form-control"
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    {typeof value[1] === 'string'
                      ? value[0] === 'file'
                        ? 'N/A'
                        : value[1]
                      : value[0] === 'file'
                      ? 'N/A'
                      : ''}
                  </p>
                </div>
              </div>
            </Col>
          ) : (
            ''
          )}
        </>
      );
    });
  };

  const [selectTransaction, setSelectTransaction] = useState<any>([]);
  //Model to View Transaction
  const viewTransationPopup = () => {
    switch (viewFlag) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => {
              setViewFlag(false);
            }}
          >  
           <div className="modal-header">
                <button
                  aria-hidden={true}
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    setViewFlag(false);
                  }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up"> {txnHeading}  </h6>
              </div>

            <div className="modal-body">
              <div className="">
                {dataTxn ? (
                  <ViewTxnFields data={selectTransaction} />
                ) : (
                  // <Row>{renderData()}</Row>

                  ''
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  return (
    <>
      <Container fluid>
        <ToastContainer limit={1} />
        {/* <Header /> */}

        <div className="mx-3">

        <Row>
          <Col md="12">  
                <Row>
                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-warning">
                              <i className="tim-icons  icon-tv-2" />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">Total AMC Users</p>
                              {loading ? (
                                <i
                                  className="fa fa-spinner fa-spin fa-2x"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <CardTitle tag="h3" className="text-primary">
                                  {data.total_amc_users}
                                </CardTitle>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-tv-2" /> Total AMC
                          Users
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>

                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-primary">
                              <i className="tim-icons icon-chart-bar-32" />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">Total Funds</p>
                              {loading ? (
                                // <img src="assets/loader.svg" width='50' /> :
                                <i
                                  className="fa fa-spinner fa-spin fa-2x"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <CardTitle tag="h3" className="text-primary">
                                  {data.total_funds}
                                </CardTitle>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-chart-bar-32" /> Total Funds
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>

                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-success">
                              <i className="tim-icons icon-credit-card" />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">
                                Total Funds Accounts
                              </p>

                              {loading ? (
                                <i
                                  className="fa fa-spinner fa-spin fa-2x"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <CardTitle tag="h3" className="text-primary">
                                  {data.total_fund_accounts}
                                </CardTitle>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-credit-card" />
                          Total Funds Accounts
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>

                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-info">
                              <i className="tim-icons icon-shape-star" />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">
                                Total SMA Accounts
                              </p>

                              {loading ? (
                                <i
                                  className="fa fa-spinner fa-spin fa-2x"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <CardTitle tag="h3" className="text-primary">
                                  {data.total_sma_accounts}
                                </CardTitle>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-shape-star" /> Total SMA
                          Accounts
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>

                {/* 2nd row */}
                <Row>
                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-primary">
                              <i className="tim-icons  icon-single-02" />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">
                                Total Unit Holders
                              </p>
                              {loading ? (
                                <i
                                  className="fa fa-spinner fa-spin fa-2x"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <CardTitle tag="h3" className="text-primary">
                                  {data.total_unitholders}
                                </CardTitle>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons  icon-single-02" /> Total Unit
                          Holders
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>

                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-success">
                              <i className="tim-icons icon-money-coins" />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">Total Fund Amount</p>
                              {loading ? (
                                <i
                                  className="fa fa-spinner fa-spin fa-2x"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <CardTitle tag="h3" className="text-primary">
                                  {data.total_fund_amount &&
                                    ConvertToExponential(
                                      data.total_fund_amount,
                                      2
                                    )}
                                </CardTitle>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-money-coins" /> Total
                          Fund Amount
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>

                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-info">
                              <i className="tim-icons icon-shape-star" />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">Total SMA Amount</p>
                              {loading ? (
                                <i
                                  className="fa fa-spinner fa-spin fa-2x"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <CardTitle tag="h3" className="text-primary">
                                  {data.total_sma_amount &&
                                    ConvertToExponential(
                                      data.total_sma_amount,
                                      2
                                    )}
                                </CardTitle>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-shape-star" /> Total SMA
                          Amount
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
            
          
          </Col>
        </Row>

        <Row>
          <Col md="12">   
                <Row className="mb-2">
                  <Col md="2"> 
                    <FormGroup>
                    <ReactTooltip	
                      textColor="white"	
                      backgroundColor="black"	
                      effect="float"	
                    /> 	
                      <input
                        type="date"
                        value={fromDate}
                        data-tip="From"
                        className="form-control"
                        onChange={(e) => {
                          setFromDate(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="2">
                   
                    <FormGroup>
                    <ReactTooltip	
                      textColor="white"	
                      backgroundColor="black"	
                      effect="float"	
                    />
                      <input
                        type="date"
                        value={toDate}
                        data-tip="To"
                        className="form-control"
                        onChange={(e) => {
                          setToDate(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="2">
                    <button
                      className="btn btn-primary mt-a"
                      onClick={() => {
                        getAllTransaction();
                      }}
                    >
                      {' '}
                      Search{' '}
                    </button>
                  </Col>
                </Row>

                {/* Activites */}
                <Row>
                  <Col lg="3" md="6">
                    <Card className="h-450">
                      <CardHeader>
                        <CardTitle>
                          <Row>
                            <Col xs="9">
                              <h4>  <i className="text-primary tim-icons icon-pencil mr-1"></i> Registered</h4>
                            </Col>
                            <Col xs="3">
                              <div className="numbers">
                                {loadingTxn ? (
                                  <i
                                    className="fa fa-spinner fa-spin fa-2x"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <CardTitle className="flex-end text-primary" tag="h4">
                                    {transaction.registered_txn_count
                                      ? transaction.registered_txn_count
                                      : '0'}
                                  </CardTitle>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </CardTitle>
                      </CardHeader>
                        <div className="card-body">
                          {transaction.registered_txn_count > 0 ?
                            transaction.registered_txn.map((txnItem, index) => {
                              return (
                                <div className="" key={index} onClick={() => {
                                  setViewFlag(true);
                                  setSelectTransaction(txnItem)
                                  let objArr = Object.entries(txnItem);
                                  setDataTxn(objArr);
                                  setTxnHeading(CapTxnType(txnItem.txnr_type))
                                }}>  
                                  <h5 className="text-border mb-2">  	
                                  <i className="tim-icons icon-minimal-right mr-2 "></i>	
                                    <span className="float-right text-primary">  {txnItem.net_amount}  </span>	
                                    {/* <span className="text-primary"> {txnItem.txn_id} </span>   */}
                                    <span> {CapTxnType(txnItem.txnr_type)} 	</span> <br/><small className="text-muted ml-4"> {txnItem.txn_id}  </small>
                                  </h5>	

                                </div>
                              )
                            })
                            : 'No Data'
                          }
                      </div>
                    </Card>
                  </Col>

                  <Col lg="3" md="6">
                    <Card className="h-450">
                      <CardHeader>
                        <CardTitle>
                          <Row>
                            <Col xs="9">
                              <h4> <i className="text-primary tim-icons icon-zoom-split mr-1"></i>  Compliance</h4>
                            </Col>
                            <Col xs="3">
                              <div className="numbers">
                                {loadingTxn ? (
                                  <i
                                    className="fa fa-spinner fa-spin fa-2x"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <CardTitle className="flex-end text-primary" tag="h3">
                                    {transaction.compliance_txn_count}
                                  </CardTitle>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </CardTitle>
                      </CardHeader>
                      <div className="card-body">
                          {transaction.compliance_txn_count > 0 ?
                            transaction.compliance_txn.map((txnItem, index) => {
                              return (
                                <div className=" " key={index} onClick={() => {
                                  setViewFlag(true);
                                  setSelectTransaction(txnItem)
                                  let objArr = Object.entries(txnItem);
                                  setDataTxn(objArr);
                                  setTxnHeading(CapTxnType(txnItem.txnr_type))
                                }}> 

                                  <h5 className="text-border mb-2"> 	
                                  
                                      <i className="tim-icons icon-minimal-right mr-2 "></i>	
                                      <span className="float-right text-primary">  {txnItem.net_amount}  </span>	
                                      {/* <span className="text-primary"> {txnItem.txn_id} </span>   */}
                                        <span> {CapTxnType(txnItem.txnr_type)} 	</span> <br/><small className="text-muted ml-4"> {txnItem.txn_id}  </small>
                                  </h5>	

                                </div>
                              )
                            })
                            : 'No Data'
                          }
                        </div>
                    </Card>
                  </Col>

                  <Col lg="3" md="6">
                    <Card className="h-450">
                      <CardHeader>
                        <CardTitle>
                          <Row>
                            <Col xs="9">
                              <h4> <i className="text-primary tim-icons icon-settings-gear-63 mr-1"> </i>Operations</h4>
                            </Col>
                            <Col xs="3">
                              <div className="numbers">
                                {loadingTxn ? (
                                  <i
                                    className="fa fa-spinner fa-spin fa-2x"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <CardTitle className="flex-end text-primary" tag="h3">
                                    {transaction.operation_txn_count}
                                  </CardTitle>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </CardTitle>
                      </CardHeader>

                      <CardBody>	
                        {transaction.operation_txn_count > 0 ?
                          transaction.operation_txn.map((txnItem, index) => {
                            return (
                              <div className="p-1 txn-size" key={index} onClick={() => {
                                setViewFlag(true);
                                setSelectTransaction(txnItem)
                                let objArr = Object.entries(txnItem);
                                setDataTxn(objArr);
                                setTxnHeading(CapTxnType(txnItem.txnr_type))
                              }}>  
                                <h5 className="text-border mb-2">  	
                                  <i className="tim-icons icon-minimal-right mr-2 "></i>	
                                  <span className="float-right text-primary">  {txnItem.net_amount}  </span>	
                                  {/* <span className="text-primary"> {txnItem.txn_id} </span> 	  */}
                                      <span> {CapTxnType(txnItem.txnr_type)} 	</span><br/><small className="text-muted ml-4"> {txnItem.txn_id}  </small>
                                 
                                </h5>	

                              </div>
                            )
                          })
                          : 'No Data'
                        }
                        </CardBody>
                    </Card>
                  </Col>

            <Col lg="3" md="6">
              <Card className=" h-450">  
                <CardHeader>	
                  <CardTitle  >	
                    <Row>	
                        <Col xs="9">	
                          <h4> <i className=" text-primary fa fa-check"></i> Complete</h4>	
                        </Col>	
                        <Col xs="3">	
                          <div className="numbers">	
                          {loadingTxn ?	
                            <i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>:	
                            <CardTitle className="flex-end text-primary" tag="h3">{transaction.completed_txn_count}</CardTitle>}	
                          </div>	
                        </Col>	
                      </Row>	
                  </CardTitle>	
               </CardHeader>
               <CardBody>	
                {transaction.completed_txn_count > 0 ?
                  transaction.completed_txn.map((txnItem, index) => {
                    return (
                      <div className=" txn-size" key={index} onClick={() => {
                        setViewFlag(true);
                        setSelectTransaction(txnItem)
                        let objArr = Object.entries(txnItem);
                        setDataTxn(objArr);
                        setTxnHeading(CapTxnType(txnItem.txnr_type))
                      }}> 
                        <h5 className="text-border mb-2">  	
                          <i className="tim-icons icon-minimal-right mr-2 "></i>	
                          <span className="float-right text-primary">  {txnItem.net_amount}  </span>	
                          {/* <span className=""> {txnItem.txn_id}  </span>	   */}
                          <span> {CapTxnType(txnItem.txnr_type)} 	</span><br/><small className="text-muted ml-4"> {txnItem.txn_id}  </small>	 
                        </h5>	
                     

                      </div>
                    )
                  })
                  : 'No Data'
                }
                </CardBody>
              </Card>
            </Col> 
                </Row>
               
             
          </Col>
        </Row> 

            {/* <Row>
              <Col md="7">
                <Card>
                  <CardBody>
                    <table className="table" id="myTable">
                      <thead>
                        <tr>
                          <th>Transaction Type</th>
                          <th>Transactions Count</th>
                          <th>Transactions Amount</th>
                        </tr>
                      </thead>
                        <tbody id="myUL">
                          <tr>
                            <td>Sale Of Unit</td>
                            <td>{saleOfUnitData.count}</td>
                            <td>{saleOfUnitData.amount}</td>
                          </tr>
                          <tr>
                            <td>Fee Payment</td>
                            <td>{data.recent_txns.feepayment_count}</td>
                            <td>{data.recent_txns.feepayment_amount}</td>
                          </tr>
                          <tr>
                        <td>Management Fee</td>
                        <td>{inflowData.count}</td>
                        <td>{inflowData.amount}</td>
                      </tr>
                    </tbody>
                    </table>
                  </CardBody>
                </Card>
              </Col>

              <Col sm="2">
                {loading ? (
                  <i
                    className="fa fa-spinner fa-spin fa-2x"
                    aria-hidden="true"
                  ></i>
                ) : (
                  <div className="bar-chart">
                    <PieChart
                      data={[
                        {
                          title: 'Complete',
                          value: +transaction.completed_txn_percentage,
                          color: '#056b6b',
                        },
                        {
                          title: 'Register',
                          value: +transaction.registered_txn_percentage,
                          color: '#374e4e',
                        },
                        {
                          title: 'Reject',
                          value: +transaction.rejected_txn_percentage,
                          color: '#b0fdfd',
                        },
                      ]}
                      label={({ dataEntry }) =>
                        `${Math.round(dataEntry.percentage)}%`
                      }
                      labelStyle={{
                        fontSize: '6px',
                      }}
                    />
                    <h5 className={'text-center'}>Transactions</h5>
                  </div>
                )}
              </Col>
              <Col sm="2">
                <div className="bar-chart">
                  <PieChart
                    data={[
                      { title: 'Complete', value: +transaction.completed_txn_amount_percentage, color: '#056b6b' },
                      { title: 'Register', value: +transaction.registered_txn_amount_percentage, color: '#374e4e' },
                      { title: 'Reject', value: +transaction.rejected_txn_amount_percentage, color: '#b0fdfd' },
                    ]}
                    label={({ dataEntry }) =>
                      `${Math.round(dataEntry.percentage)}%`
                    }
                    labelStyle={{
                      fontSize: '6px',
                    }}
                  />
                  <h5 className={'text-center'}>Amount</h5>
                </div>
              </Col>
            </Row> */}
         
          {viewTransationPopup()}
          </div>
      </Container>
    </>
  );
};

export default Dash;

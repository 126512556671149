import React from 'react';
import { Link } from 'react-router-dom';
import "./Home.css"



export default function Home() {

  return (
    <div data-tid="container">
      <h2>Home</h2>
      <Link to="/login" replace>to Counter</Link>
    </div>
  );
}

import axios from 'axios';
import Config from './../../config/index';

/**
 * @param email
 */
const getNatureOfTx = async (email: string) => {
  const url = `${Config.baseUrl}/mastertxn/not?email=${email}`;
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
};
/**
 * @param email
 */
const getModeOfTx = async (email: string) => {
  const url = `${Config.baseUrl}/mastertxn/tot?email=${email}`;
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
};
/**
 * @param email
 */
const getModeOfPayments = async (email: string) => {
  const url = `${Config.baseUrl}/mastertxn/mop?email=${email}`;
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
};

/**
 * @param email
 */
const getTxnCatagory = async (email: string) => {
  const url = `${Config.baseUrl}/mastertxn/txn-catagory?email=${email}`;
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
};

/**
 *
 * @param email
 */
const getAllPendingTransactions = async (
  email: string,
  status: string,
  search_value: string,
  created_by: string = '',
  page_number: string = ''
) => {
  const url = `${Config.baseUrl}/transaction?email=${email}&status=${status}&search_value=${search_value}&created-by=${created_by}&page_number=${page_number}&page_size=10`;
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
};

const updateTransactionStatus = async (
  email: string,
  status: string,
  trx_id: string,
  note: string,
  check_list: any
) => {
  const url = `${Config.baseUrl}/transaction/update-status`;
  const result: any = await axios.put(
    url,
    {
      email,
      status,
      trx_id,
      note,
      check_list,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
};

// add Equity
const addEquityTransaction = async (
  email: string,
  fund: string,
  account_no: string,
  name_of_beneficiary: string,
  beneficiary_bank: string,
  beneficiary_branch: string,
  beneficiary_account: string,
  instrument_date: string,
  symbol: string,
  instrument_no: string,
  instrument_type: string,
  type_of_transaction: string,
  trade_type: string,
  brokage: string,
  gross_amount: string,
  fed: string,
  trade_date: string,
  sst: string,
  commission: string,
  settlement_date: string,
  net_amount: string,
  broker_code: string,
  // broker_name: string,
  volume: string,
  avg_rate: string,
  amc_name: string,
  trx_id: string,
  broker_name: string,
  txn_status?: string,
  note?: string,
  check_list?: any,
  updated_by_role?: string
) => {
  let type_of_security = symbol;
  const url = `${Config.baseUrl}/transaction/equity-settlement/`;
  const result: any = await axios.post(
    url,
    {
      email,
      fund,
      account_no,
      name_of_beneficiary,
      beneficiary_bank,
      beneficiary_branch,
      beneficiary_account,
      instrument_date,
      symbol,
      type_of_security,
      instrument_no,
      instrument_type,
      type_of_transaction,
      trade_type,
      brokage,
      gross_amount,
      fed,
      trade_date,
      sst,
      commission,
      settlement_date,
      net_amount,
      broker_code,
      volume,
      avg_rate,
      amc_name,
      trx_id,
      broker_name,
      txn_status,
      note,
      check_list,
      updated_by_role,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
};

//display type
const getCaptype = async () => {
  const url = `${Config.baseUrl}/transaction/txntypes`;
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
};

const getCoListing = async (email: string, amcCode: string) => {
  const url = `${Config.baseUrl}/amc/members-by-code?email=${email}&code=${amcCode}`;
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
};

const getAllTransactions = async (
  email: string,
  status: string,
  search_value: string,
  created_by: string = '',
  page_number: string = '',
  create_email: string = ''
) => {
  const url = `${Config.baseUrl}/transaction?email=${email}&status=${status}&search_value=${search_value}&created-by=${created_by}&page_number=${page_number}&page_size=10&create_email=${create_email}`;
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
};

/**
 *
 * @param email
 */
const getAllAmcTransactions = async (
  email: string,
  amc_name,
  status: string,
  search_value: string,
  created_by: string = '',
  page_number: string = ''
) => {
  const url = `${Config.baseUrl}/transaction/amc-txns?email=${email}&status=${status}&search_value=${search_value}&created-by=${created_by}&page_number=${page_number}&page_size=10&amc_code=${amc_name}`;
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
};

const updateBulkTransactionStatus = async (
  email: string,
  role: string,
  status: string,
  note: string,
  check_list: any,
  data: any
) => {
  const url = `${Config.baseUrl}/transaction/bulk-update`;
  const result: any = await axios.post(
    url,
    {
      email,
      role,
      status,
      note,
      check_list,
      data,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
};

export {
  getAllAmcTransactions,
  getCoListing,
  getAllTransactions,
  getTxnCatagory,
  getCaptype,
  updateTransactionStatus,
  getNatureOfTx,
  addEquityTransaction,
  getModeOfTx,
  getModeOfPayments,
  getAllPendingTransactions,
  updateBulkTransactionStatus,
};

import React from "react";
import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
} from "reactstrap";
import { login, twoFALogin } from "../../stores/services/auth.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
// import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import {
  getCaptype,
  getTxnCatagory,
} from "../../stores/services/transactions.service";
import Particles from "react-particles-js";
import ParticlesComp from "../ParticlesComp";

const Login = () => {
  let first_input: HTMLInputElement | null = null;
  let second_input: HTMLInputElement | null = null;
  let third_input: HTMLInputElement | null = null;
  let fourth_input: HTMLInputElement | null = null;
  let fifth_input: HTMLInputElement | null = null;
  let sixth_input: HTMLInputElement | null = null;
  const history = useHistory();
  const [popup, setPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [Loading, setLoading] = useState(false);
  const [twoFACode, setTwoFACode] = useState("");
  const [firstCode, setFirstCode] = useState("");
  const [secondCode, setSecondCode] = useState("");
  const [thirdCode, setThirdCode] = useState("");
  const [fourthCode, setFourthCode] = useState("");
  const [fifthCode, setFifthCode] = useState("");
  const [sixthCode, setSixthCode] = useState("");
  const [inputType, setInputType] = useState("password");
  const emailRegex = new RegExp(
    "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
  );
  React.useEffect(() => {
    const getCapTaxTypes = async () => {
      try {
        const response = await getCaptype();
        sessionStorage.setItem(
          "txnType",
          JSON.stringify(response.data.txntypes)
        );
      } catch (error) {}
      try {
        const response = await getTxnCatagory("");
        sessionStorage.setItem(
          "txnTypeCatagory",
          JSON.stringify(response.data.txn_catagory)
        );
        //console.log('txn', response.data.txn_catagory)
      } catch (error) {}
    };
    getCapTaxTypes();
  }, []);
  const handleClick = () => {
    inputType === "text" ? setInputType("password") : setInputType("text");
  };
  const validate = async () => {
    let emailErr,
      passErr = "";
    email.trim() === ""
      ? (emailErr = "Email Required")
      : emailRegex.test(email) !== true
      ? (emailErr = "Invalid Email")
      : (emailErr = "");
    password.trim() === "" ? (passErr = "Password Required") : (passErr = "");
    if (emailErr || passErr) {
      setEmailError(emailErr);
      setPasswordError(passErr);
      return false;
    } else {
      return true;
    }
  };
  const loginBtn = async () => {
    const isValid = await validate();
    if (isValid) {
      try {
        setLoading(true);
        const response = await login(email, password);
        // if (response.data.data.two_fa_enabled === "true") {
        //   toast.success(response.data.message);
        //   setPopup(true);
        //   setLoading(false);
        // } else {
          toast.success(response.data.message);
          sessionStorage.setItem("name", response?.data?.data?.name);
          sessionStorage.setItem("email", email);
          sessionStorage.setItem("role", response?.data?.data?.role);
          sessionStorage.setItem("features", response?.data?.data?.features);
          sessionStorage.setItem("token", response?.data?.data?.accessToken);
          sessionStorage.setItem(
            "loggedInUser",
            JSON.stringify(response?.data?.data)
          );
          setLoading(false);
          // if (response.data.data.role == "SIGNATORY-A") {
          //   history.replace("/comp-sign-transactions/signatory-a");
          // } else if (response.data.data.role == "SIGNATORY-B") {
          //   ("");
          //   history.replace("/comp-sign-transactions/signatory-b");
          // } else {
            history.replace("/dashboard");
          // }
        // }
      } catch (error) {
        console.log(error);
        console.log("error.response.message", error.response);
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
      setLoading(false);
    }
  };
  const login2FaBtn = () => {
    setLoading(true);
    if (!firstCode) {
      setLoading(false);
      return;
    }
    if (!secondCode) {
      setLoading(false);
      return;
    }
    if (!thirdCode) {
      setLoading(false);
      return;
    }
    if (!fourthCode) {
      setLoading(false);
      return;
    }
    if (!fifthCode) {
      setLoading(false);
      return;
    }
    if (!sixthCode) {
      setLoading(false);
      return;
    }
    setTwoFACode(
      `${firstCode}${secondCode}${thirdCode}${fourthCode}${fifthCode}${sixthCode}`
    );
    // twoFALogin(
    //   email,
    //   `${firstCode}${secondCode}${thirdCode}${fourthCode}${fifthCode}${sixthCode}`
    // )
    //   .then((response) => {
    //     setLoading(false);
    //     //console.log(response);
    //     toast.success(response.message);
    //     sessionStorage.setItem('email', email);
    //     sessionStorage.setItem('role', response.data.data.role);
    //     sessionStorage.setItem('features', response.data.data.features);
    //     sessionStorage.setItem(
    //       'loggedInUser',
    //       JSON.stringify(response.data.data)
    //     );
    //     history.replace('/dashboard');
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     console.log(err);
    //     toast.error(err.message);
    //   });
  };
  return (
    <>
      <ParticlesComp />

      <div className="container-login">
        <ToastContainer />
        <div className={`fa-popup ${popup ? "" : "hidden"}`}>
          <div className="popup card-login card-white card mb-0">
            <p className="  text-black">
              {" "}
              <strong> Two-Factor Authentication</strong>{" "}
            </p>
            <p className="text-black">Please enter One-Time Password (OTP)</p>
            <div className="digit-holder mx-auto ">
              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    first_input = input;
                  }}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setFirstCode(e.target.value);
                      second_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>

              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    second_input = input;
                  }}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setSecondCode(e.target.value);
                      third_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>
              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    third_input = input;
                  }}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setThirdCode(e.target.value);
                      fourth_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>

              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    fourth_input = input;
                  }}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setFourthCode(e.target.value);
                      fifth_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>
              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    fifth_input = input;
                  }}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setFifthCode(e.target.value);
                      sixth_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>
              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    sixth_input = input;
                  }}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setSixthCode(e.target.value);
                    }
                    return;
                  }}
                />
              </FormGroup>
            </div>
            <div className="btn-holder">
              <button
                className="btn btn-primary bg-positive"
                onClick={login2FaBtn}
                disabled={Boolean(Loading)}
              >
                {Loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Confirm</span>
                )}
              </button>
              <button
                className="btn btn-default bg-negative"
                onClick={() => {
                  setPopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        <Col className="" lg="4" md="6">
          <Card className="card-login card-white  bg-transparent">
            <CardHeader className="d-flex justify-content-center py-15 ">
              <img
                className="opacity-img"
                alt=""
                src={require("../../assets/macs-logo.png")}
                width={"156px"}
                // src='https://digitalcustodian.co/images/brand/DCC-Logo.svg'  width={"156px"}
              />
              <br />
            </CardHeader>
            <CardBody>
              <div className="login-section">
                <label className="">Email</label>
                <FormGroup>
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError("");
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        loginBtn();
                      }
                    }}
                  />
                  {emailError ? (
                    <p className="error-labels login-err">{emailError}</p>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </div>
              <div className="">
                <label>Password</label>
                <FormGroup>
                  <input
                    placeholder="Enter Your Password"
                    type={inputType}
                    className="form-control"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError("");
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        loginBtn();
                      }
                    }}
                  />
                  {/* {inputType === 'text' ? (
                  <MdVisibility
                    className="visibility-icon"
                    onClick={() => handleClick()}
                  />
                ) : (
                  <MdVisibilityOff
                    className="visibility-icon"
                    onClick={() => handleClick()}
                  />
                )} */}
                  {passwordError ? (
                    <p className="error-labels pass-err">{passwordError}</p>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </div>
            </CardBody>

            <CardFooter className="d-flex  space-between">
              <div className=" ">
                <button
                  className="btn btn-primary "
                  onClick={loginBtn}
                  disabled={Boolean(Loading)}
                >
                  {Loading ? (
                    <>
                      <span
                        className="fa fa-spinner fa-spin"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  ) : (
                    <span className="login-text">Login</span>
                  )}
                </button>
              </div>

              <Link to="/forgot-password  " className="mt-2" replace>
                Forgot Password?
              </Link>
            </CardFooter>
          </Card>
        </Col>
      </div>
    </>
  );
};

export default Login;
